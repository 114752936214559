var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ls-center"},[_c('div',{staticClass:"ps-section__header ls-page-header-no-bg",staticStyle:{"background-image":"url(/img/cover_classified_list.jpg)"}}),_c('div',{staticClass:"ps-section__content ls-page-content mb-5"},[_c('h3',{staticClass:"container ls-page-header-subtitle"},[_vm._v(" Use our listings to find Pahukama BnB accomodation rentals and place your or find listings to buy, sell or rent services, properties, cars, electronics, jobs, dating and other items in Zimbabwe. Get services and products from other members on this platform, cheaper and reliably. ")]),(_vm.auth.isAuthenticated)?_c('div',{staticClass:"row ls-center",staticStyle:{"padding":"50px"}},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3"},[_c('router-link',{staticClass:"ps-btn btn-sm",attrs:{"to":{
          name: 'my_listings',
        }}},[_vm._v(" My Listings ")])],1),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3"},[_c('button',{staticClass:"ps-btn btn-sm",on:{"click":function($event){return _vm.pahukamaBNB()}}},[_vm._v(" Pahukama BnB")])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3"},[_c('router-link',{staticClass:"ps-btn btn-sm",attrs:{"to":{
          name: 'create_listing',
        }}},[_vm._v(" Create Listing ")])],1),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3"},[_c('router-link',{staticClass:"ps-btn btn-sm",attrs:{"to":{
          name: 'create_listing',
        }}},[_vm._v(" Listings Reviews ")])],1)]):_c('div',{staticClass:"align-items-center justify-content mb-4 mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"m-3 ps-btn btn-sm",on:{"click":function($event){return _vm.viewListings()}}},[_vm._v(" View Listings")]),_c('button',{staticClass:"m-3 ps-btn btn-sm",on:{"click":function($event){return _vm.createListing()}}},[_vm._v(" Create Listing")]),_c('button',{staticClass:"m-3 ps-btn btn-sm",on:{"click":function($event){return _vm.myListings()}}},[_vm._v(" My Listings")]),_c('button',{staticClass:"m-3 ps-btn btn-sm",on:{"click":function($event){return _vm.pahukamaBNB()}}},[_vm._v(" Pahukama BnB")])])])]),_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
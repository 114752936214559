<template>
  <section class="ls-center">
    <div class="ps-section__header ls-page-header-no-bg" style="background-image: url(/img/cover_classified_list.jpg)">
    </div>
    <div class="ps-section__content ls-page-content mb-5">
      <h3 class="container ls-page-header-subtitle">
        Use our listings to find Pahukama BnB accomodation rentals and place your or find listings to buy, sell or rent
        services, properties, cars, electronics, jobs, dating and other items in Zimbabwe. Get services and products from
        other members on this platform, cheaper and reliably.
      </h3>
      <div class="row ls-center" v-if="auth.isAuthenticated" style="padding: 50px">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3">
          <router-link :to="{
            name: 'my_listings',
          }" class="ps-btn btn-sm">
            My Listings
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3">
          <button @click="pahukamaBNB()" class="ps-btn btn-sm">
            Pahukama BnB</button>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3">
          <router-link :to="{
            name: 'create_listing',
          }" class="ps-btn btn-sm">
            Create Listing
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3">
          <router-link :to="{
            name: 'create_listing',
          }" class="ps-btn btn-sm">
            Listings Reviews
          </router-link>
        </div>
      </div>
      <div v-else class="align-items-center justify-content mb-4 mt-4">
        <div class="row">
          <div class="col">
            <button @click="viewListings()" class="m-3 ps-btn btn-sm">
              View Listings</button>
            <button @click="createListing()" class="m-3 ps-btn btn-sm">
              Create Listing</button>
            <button @click="myListings()" class="m-3 ps-btn btn-sm">
              My Listings</button>
            <button @click="pahukamaBNB()" class="m-3 ps-btn btn-sm">
              Pahukama BnB</button>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </section>

  <!--

    <section class="ps-section--account ls-section-account">
  </section>
  
  -->
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseRunner",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    $("#toggle_no_wrap").on("click", function () {
      $("#listing_cats").toggleClass("listings-categories");
      $(this).toggleClass("icon-chevron-down-circle");
      $(this).toggleClass("icon-chevron-up-circle");
    });
  },
  data() {
    return {
      runnerCategories: [],
      myRequests: [],
      pendingRequests: [],
      completedRequests: [],
      inProgressRequests: [],
    };
  },
  methods: {
    getRunnersDetails() {
      axios.get("v1/getrunner/get-runners").then((response) => {
        //console.log("Get Runner Requests : " + JSON.stringify(response.data.data));
        this.myRequests = response.data.data;
        for (var i = 0; i < this.myRequests.length; i++) {
          switch (this.myRequests[i].status) {
            case "pending":
              this.pendingRequests.push(this.myRequests[i]);
              break;
            case "completed":
              this.completedRequests.push(this.myRequests[i]);
              break;
            case "in progress":
              this.inProgressRequests.push(this.myRequests[i]);
              break;
          }
        }
        // this.pendingRequests = response.data.data.length;
      });
      axios.get("v1/getrunner/runner-categories").then((response) => {
        //console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerCategories = response.data;
      });
    },
    createListing() {
      this.$store.commit("auth/next", "create_listing");
      this.$router.push({ name: "login" });
    },

    myListings() {
      this.$store.commit("auth/next", "my_listings");
      this.$router.push({ name: "login" });
    },

    viewListings() {
      this.$router.push({ name: "listings" });
    },

    pahukamaBNB() {
      this.$router.push({ name: "listings.pahukama.bnb" });
    }
  },
};
</script>

<style scoped></style>
